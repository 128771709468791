.body{
  overflow-x: hidden;
}
.mycard{
  padding: 10px;
  border: none;
  border-radius: 20px;
}
.card-header{
  background: transparent;
  border-bottom: 1px solid #f7f7f7;
}
.icon-color{
  color: #64748b;
}
.f-10{
  font-size: 10px;
}
.ml-5{
  margin-left: 5px;
}
.cursor{
  cursor: pointer;
}
.light{
  color: #c4c4c4;
}
.statCard{
  padding: 30px;
  border: none;
  border-radius: 20px;
}
.table tr th,td{
  padding: 10px 5px;
  font-size: 14px;
  border-bottom: 1px solid #f0f0f0;
}
.wrap-text{
  width: 250px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;
}
.fr{
  float: right;
}
.text-right{
  text-align: right;
}
.chat-bottom{
  display: flex;align-content: center;
}
.three-dots{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.word-counter{
  font-size: 10px;
  float: right;
  /* flex: 1; */
  align-self: center;
  text-align: right;
}
.code-container {
  max-width: 100%;
  overflow: hidden;
  font-size: 12px;
}

.code-container pre {
  white-space: pre-wrap;
}

.my-modal{
  width: 100%;
  height: 100vh;
  border: 1px solid red;
  position: absolute;
  z-index: 999;
}

.darkBG {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modalMain {
  width: 500px;
  height: auto;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.modalHeader {
  height: 50px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.heading {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.modalContent {
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
}

.modalActions {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.actionsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.closeBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: white;
  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: -7px;
  margin-right: -7px;
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: translate(-4px, 4px);
}

.deleteBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.deleteBtn:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  transform: translateY(-5px);
  background: #ff3e4e;
}

.cancelBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.cancelBtn:hover {
  box-shadow: none;
  transform: none;
  background: whitesmoke;
}

.google-icon{
  height: 20px;
}

.btn-google{
  background-color: white;
  box-shadow: 0px 18px 20px -6px rgba(232,232,232,0.5);
  border: 1px solid black;
}
.btn-google:hover{
  border: 1px solid black;
}
.task-icon{
  cursor: pointer;
  background-color: #eaeaea;
  border-radius: 1px;
  height: 15px;
  width: 15px;
  transition-duration: 0.5s;
}
.task-icon:hover{
  height: 17px;
  width: 17px;
  background-color: #c4c4c4;
  border-radius: 3px;
}

.high{
  background-color: #401a94 !important;
}

.low{
  background-color: #579bfc !important;
}

.critical{
  background-color: #323232 !important;
}

.medium{
  background-color: #995fea !important;
}

.pending{
  background-color: #c4c4c4 !important;
}

.in-progress{
  background-color: #fdac3d !important;
}

.completed{
  background-color: #00c875 !important;
}

.middle{
  vertical-align: middle;
}
.white{
  color: white;
}
.task-select{
  width: 100%;
  background: transparent;
  border: none;
  color: white;
}
.ql-editor{
  height: 400px !important;
  /* height: 842px !important; */
}

.feedback-hidden{
  height: 190px;
  width: 2%;
  position: absolute;
  z-index: 9999;
  bottom: 50px;
  right: 0;
  text-align: center;
  background: #ffffffba;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  backdrop-filter: blur(10px);
  cursor: pointer;
}

.feedback-container{
  height: 190px;
  width: 25%;
  position: absolute;
  z-index: 9999;
  bottom: 50px;
  right: 0;
  padding: 10px;
  background: #ffffffba;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  backdrop-filter: blur(10px);
  box-shadow: 16px 14px 17px -10px rgba(219,219,219,0.5);
}

.feedback-header{
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  margin-top: 20px;
}

.align-center{
  align-items: center;
}

.add-buttons{
  flex: 1;display: flex;justify-content: end;margin-right: 20px;
}
.init-buttons{
  flex: 1;display: flex;
}
/* .footer{
  font-size: 12px;
  position: absolute;
  bottom: 0;
  left: 50%;
} */
.footer{
  font-size: 12px;
  position: absolute;
  bottom: 0;
  width: auto;
  background: #dbeafe;
  padding-top: 10px;
  padding-left: 11.3%;
  padding-right: 11.3%;
}
.card-element{
  border: 1px solid #e4e4e4;
  padding: 10px;
  border-radius: 2px;
}
.lh12{
  line-height: 12px;
}
.copy-message{
  font-family: Arial, sans-serif;
  font-size: 11pt;
  background-color: white;
  color: black;
}
.btnCoupon{
  position: absolute;
  bottom: 70px;
  right: 39px;
  background: #dadada;
  color: black;
  border: #dadada;
}